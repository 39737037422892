<template>
  <div class="shopping-cart">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }"
        ><i class="el-icon-s-home"></i
      ></el-breadcrumb-item>
      <el-breadcrumb-item>购物车</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <h3>购物车</h3>
      <div v-if="cart.length != 0">
        <!-- <el-table>
          <el-table-column label="图片"></el-table-column>
          <el-table-column label="商品名称"></el-table-column>
          <el-table-column label="单价"></el-table-column>
          <el-table-column label="型号"></el-table-column>
          <el-table-column label="数量"></el-table-column>
          <el-table-column label="总计"></el-table-column>
          <el-table-column label=""></el-table-column>
        </el-table> -->
        <div class="cart-table">
          <div class="cart-table-header">
            <div>图片</div>
            <div>商品名称</div>
            <div>单价</div>
            <div>型号</div>
            <div>数量</div>
            <div>总计</div>
          </div>
          <div class="cart-table-list">
            <div
              v-for="(item, index) in cart"
              :key="index"
              class="cart-table-content"
            >
              <div>
                <img
                  :src="item.img"
                  alt=""
                />
              </div>
              <div>{{item.name}}</div>
              <div>￥{{item.price}}</div>
              <div>Product 18</div>
              <div>
                <el-input-number
                  size="mini"
                  style="width: 50%"
                  v-model="item.num"
                  @change="handleChange"
                  :min="1"
                  label="描述文字"
                ></el-input-number>
              </div>
              <div>￥{{item.price * item.num}}</div>
              <div @click="delShop">移除</div>
            </div>
          </div>
        </div>
        <div style="display: flex">
          <div style="width: 60%">
            <el-form>
              <el-form-item label="请在这里输入您的优惠卷代码">
                <el-input placeholder="请在这里输入您的优惠卷代码">
                  <el-button
                    style="background-color: #ffda00"
                    slot="append"
                    type="primary"
                    >使用优惠卷</el-button
                  >
                </el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="right-bottom-pay">
            <table class="table-style">
              <tr>
                <td>商品总额</td>
                <td>￥{{shopTotal.toFixed(2)}}</td>
              </tr>
              <tr>
                <td>订单总额</td>
                <td>￥{{shopTotal.toFixed(2)}}</td>
              </tr>
            </table>
            <el-button @click="routerClick">去结账</el-button>
          </div>
        </div>
      </div>
      <div v-else>暂无添加商品到购物车</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "shoppingCart",
  data() {
    return {
      cart: [
        {
          name: "OMNIS 双面羊毛呢子大衣女短款织衫女",
          price: "1999",
          spec: "Product 18",
          num: 1,
          img: "http://shop.super-gift.com.cn/image/cache/catalog/demo/product/product-9-100x100.png",
        },
        {
          name: "OMNIS 双面羊毛呢子大衣女短款织衫女",
          price: "1999",
          spec: "Product 18",
          num: 1,
          img: "http://shop.super-gift.com.cn/image/cache/catalog/demo/product/product-9-100x100.png",
        },
      ],
      checked: true,
    };
  },
  computed: {
    shopTotal(){
      let total = 0;
      this.cart.forEach(item=>{
        total+= Number(item.price) * Number(item.num)
      })
     
      return total
    }
  },
  methods: {
    routerClick() {
      this.$router.push("/invoicing");
    },
    handleChange() {},
    // 移除
    delShop() {
        this.$confirm('确定要删除该商品?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        })
    }
  },
};
</script>

<style scoped lang="scss">
@import "../../scss/index.scss";
.shopping-cart {
  @include body;
  .cart-table {
    background: #fff;
    padding: 20px;
    .cart-table-header {
      display: flex;
      border-bottom: 1px solid #e5e5e5;
      padding: 0 10px;
      padding-bottom: 10px;
      font-size: 14px;

      div {
        &:nth-child(1) {
          width: 15%;
        }
        &:nth-child(2) {
          width: 26%;
        }
        &:nth-child(3) {
          width: 10%;
        }
        &:nth-child(4) {
          width: 10%;
        }
        &:nth-child(5) {
          width: 20%;
        }
        &:nth-child(6) {
          width: 10%;
        }
      }
    }
    .cart-table-content {
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 10px 0;
      border-bottom: 1px solid #e5e5e5;
      div {
        &:nth-child(1) {
          width: 15%;
          display: flex;
          img {
            width: 100px;
            height: 100%;
          }
        }
        &:nth-child(2) {
          width: 26%;
        }
        &:nth-child(3) {
          width: 10%;
        }
        &:nth-child(4) {
          width: 10%;
        }
        &:nth-child(5) {
          width: 20%;
        }
        &:nth-child(6) {
          width: 10%;
        }
        &:nth-child(7) {
          width: 10%;
          text-align: center;
          color: #0b6798;
          cursor: pointer;
          &:hover {
            color: #b39900;
          }
        }
      }
    }
  }
  .right-bottom-pay {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 20px;
    .table-style {
      border: 1px solid #dddddd;
      border-collapse: collapse;
      font-size: 14px;
      margin-bottom: 20px;
      td {
        padding: 5px 10px;
        width: 200px;
        text-align: right;
        border: 1px solid #dddddd;
      }
      tr {
        td:first-child {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
